<template>
  <div data-testid="add-new-patient">
    <v-btn
      v-if="isAddNewPatientEnabled"
      color="secondary"
      rounded
      @click="openDialog"
      data-testid="btn-add-patient"
      elevation="1"
      >Add a patient</v-btn
    >

    <WarningBox v-else data-testid="add-new-patient-unavailable">
      <strong>Remember:</strong> You can only add a new patient on the iPad
      provided by Novo Nordisk.
    </WarningBox>

    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <AddPatientSteps
        :site-no="siteNo"
        @close-dialog="closeDialog"
        @patient-added="patientAdded"
        ref="addPatientSteps"
      />
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import AddPatientSteps from './_AddPatientSteps.vue'
import deviceInfo from '@/utils/deviceInfo'

export default Vue.extend({
  name: 'AddNewPatient',
  props: {
    siteNo: { type: [String, Number], required: true },
  },
  components: {
    AddPatientSteps,
    WarningBox: () => import('@/components/WarningBox.vue'),
  },
  data() {
    return {
      isAddNewPatientEnabled: false,
      showDialog: false,
    }
  },
  methods: {
    guardOnNavigation() {
      return this.$refs.addPatientSteps?.guardOnNavigation()
    },
    openDialog() {
      this.showDialog = true
      this.$nextTick(() => {
        this.$refs.addPatientSteps.reset()
      })
      this.$emit('toggle', true)
    },
    closeDialog() {
      this.$emit('toggle', false)
      this.showDialog = false
    },
    patientAdded() {
      this.$emit('patient-added', true)
    },
  },
  created() {
    this.isAddNewPatientEnabled = deviceInfo.isIpadAir2019()
  },
})
</script>

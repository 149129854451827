<template>
  <div class="app-qr-code">
    <v-card-title class="font-weight-bold mt-2 pb-3"
      >Finalise the configuration on the phone</v-card-title
    >

    <v-card-text>
      <FormSectionDivider />
      <AppQrCode :data-for-qr-code="dataForQrCode" :loading="loading" />

      <v-row class="mt-6">
        <v-col class="text-right">
          <v-btn
            rounded
            @click.prevent="nextStep"
            type="submit"
            data-testid="btn-cancel"
            >Close</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import FormSectionDivider from '../shared/FormSectionDivider'
import AppQrCode from './AppQrCode'
export default {
  name: 'AppQrCodeCard',
  components: {
    AppQrCode,
    FormSectionDivider,
  },
  props: {
    loading: { type: Boolean, required: true },
    dataForQrCode: {
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
  },
  methods: {
    nextStep() {
      this.$emit('next-step', true)
    },
  },
}
</script>

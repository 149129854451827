<template>
  <v-dialog
    v-model="showTableDialog"
    max-width="527"
    persistent
    scrollable
    data-testid="double-dummy-titration-table"
  >
    <template #activator="{ on }">
      <v-icon
        color="secondary"
        dark
        v-on="on"
        data-testid="double-dummy-titration-table-open"
        class="mr-2"
        >mdi-information</v-icon
      >
    </template>

    <DialogCard @closeDialog="closeTableDialog">
      <template v-if="isLoading" #title>Loading... Please wait</template>
      <template v-else #title
        >Table 10-6: Insulin degludec/placebo (as per protocol)</template
      >
      <template #actions>
        <v-btn
          color="secondary"
          rounded
          depressed
          @click="closeTableDialog"
          class="elevation-1"
          data-testid="double-dummy-titration-table-close"
          >Close</v-btn
        >
      </template>
      <div class="mb-3 mt-3" v-if="showTableDialog && !isLoading">
        <div>
          <table>
            <tr>
              <th>Once-weekly<br />dose (U)</th>
              <th>Once-daily<br />dose (U)</th>
              <th>Once-weekly<br />dose (U)</th>
              <th>Once-daily<br />dose (U)</th>
              <th>Once-weekly<br />dose (U)</th>
              <th>Once-daily<br />dose (U)</th>
            </tr>
            <tr v-for="(row, rowIndex) in titrationTable" :key="rowIndex">
              <td v-for="(col, colIndex) in row" :key="colIndex">
                {{ col }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import DialogCard from '../../DialogCard'
import loadingState from '@/constants/loadingState'
import prescriptionService from '@/services/prescription-service'

export default {
  name: 'DoubleDummyTitrationTable',
  components: { DialogCard },
  props: {
    trialSiteNo: { type: String, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      showTableDialog: false,
      titrationTable: [],
    }
  },
  watch: {
    showTableDialog(newDialog) {
      if (newDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    closeTableDialog() {
      this.showTableDialog = false
    },
    loadData() {
      this.state = loadingState.LOADING

      prescriptionService
        .getDoubleDummyTitrationTable(this.trialSiteNo)
        .then(response => {
          const titrationValues = response.value
          const protocolValues = titrationValues.filter(
            pair => pair.onceWeeklyDose !== 0
          )
          const thirdOfSize = Math.ceil(protocolValues.length / 3)
          const temp = []
          for (let i = 0; i < thirdOfSize; i += 1) {
            const firstColElement = protocolValues[i]
            const secondColElement = protocolValues[i + thirdOfSize]
            const thirdColElement = protocolValues[i + thirdOfSize * 2]
            const row = [
              firstColElement.onceWeeklyDose,
              firstColElement.onceDailyDose,
              secondColElement?.onceWeeklyDose,
              secondColElement?.onceDailyDose,
              thirdColElement?.onceWeeklyDose,
              thirdColElement?.onceDailyDose,
            ]
            temp.push(row)
          }
          this.titrationTable = temp
          this.state = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.closeTableDialog()
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--dark.v-icon:focus::after {
  opacity: 0 !important;
}

td,
th {
  width: 100px;
  border: 1px solid $nn-D_T40;
  text-align: center;
  padding: 3px;
}
th {
  font-size: 16px;
}
table {
  border-collapse: collapse;
}

td:nth-child(2n),
th:nth-child(2n) {
  border-left: none;
}
td:nth-child(2n + 1),
th:nth-child(2n + 1) {
  border-right: none;
}

tr:nth-child(2n) {
  background-color: #f3f3f3;
}
</style>

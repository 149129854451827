<template>
  <v-row v-if="isDoseRulesLoading" key="loading" class="mt-9 mb-10">
    <v-col>
      <v-progress-circular :size="50" color="grey" indeterminate />
    </v-col>
  </v-row>

  <div v-else-if="noPrescriptionRequired" class="no-prescription-container">
    <ImgInsulinDataMissing />
    <h6 class="no-prescription-text mt-4">
      This change requires no new prescription. <br />
      Please proceed to saving this change
    </h6>
  </div>

  <div v-else key="doses">
    <template v-for="(doseRule, index) in doseRules">
      <OnceWeeklyInitialDose
        v-if="doseRule.type === prescriptionType.BASAL_ONCE_WEEKLY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <OnceDailyInitialDose
        v-else-if="doseRule.type === prescriptionType.BASAL_ONCE_DAILY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <DoubleDummyInitialDose
        v-else-if="doseRule.type === prescriptionType.DOUBLE_DUMMY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :site-no="siteNo"
        :context="context"
      />

      <BolusInitialDose
        v-else-if="doseRule.type === prescriptionType.BOLUS"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <BolusInitialCarbCountDose
        v-else-if="doseRule.type === prescriptionType.BOLUS_CARB_COUNT"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />
    </template>
  </div>
</template>

<script>
import prescriptionType from '@/constants/prescriptionType'
import initialDoseRulesMixin from './initialDoseRulesMixin'

export default {
  name: 'InitialDoses',
  components: {
    BolusInitialCarbCountDose: () => import('./_BolusInitialCarbCountDose'),
    DoubleDummyInitialDose: () => import('./_DoubleDummyInitialDose'),
    BolusInitialDose: () => import('./_BolusInitialDose'),
    OnceDailyInitialDose: () => import('./_OnceDailyInitialDose'),
    OnceWeeklyInitialDose: () => import('./_OnceWeeklyInitialDose'),
    ImgInsulinDataMissing: () =>
      import('@/components/images/ImgInsulinDataMissing.vue'),
  },
  mixins: [initialDoseRulesMixin],
  props: {
    isDoseRulesLoading: { type: Boolean, default: false },
    siteNo: { type: String, required: true },
  },
  data() {
    return {
      prescriptionType,
    }
  },
  computed: {
    noPrescriptionRequired() {
      return this.doseRules.length === 0
    },
  },
}
</script>

<style lang="scss" scoped>
.no-prescription-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 100px;
}

.no-prescription-text {
  color: $nn-true-blue;
}
</style>

<template>
  <span class="dot mr-2" :style="`background-color: ${color}`" />
</template>

<script>
export default {
  name: 'StatusDot',
  props: {
    color: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.dot {
  background-color: transparent;
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  font-size: 0;
  justify-content: center;
  height: 6px;
  min-width: 6px;
  width: 6px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-bottom: 2px;
}
</style>

<template>
  <div>
    <v-row>
      <v-col class="col-sm-6">
        <ReviewField
          data-testid="selected-patient-number"
          :display-value="newPatientInfo.patientNumber"
        >
          Patient ID
        </ReviewField>
      </v-col>

      <v-col v-if="showDosingRegimens" class="col-sm-6" />

      <v-col
        v-if="showTreatmentArm"
        class=""
        data-testid="select-treatment-arm-group"
      >
        <ReviewField
          data-testid="selected-treatment-arm"
          :is-radio-button="true"
          :display-value="treatmentArmDisplayName"
        >
          Choose treatment arm
        </ReviewField>
      </v-col>

      <v-col v-if="showDosingRegimens">
        <ReviewField
          data-testid="selected-dosing-regimen"
          :is-radio-button="true"
          :display-value="$t(`dosing-regimen.${newPatientInfo.dosingRegimen}`)"
        >
          Bolus regimen
        </ReviewField>
      </v-col>
    </v-row>

    <FormSectionDivider v-if="showSectionDivider" :use-h5="true" />

    <v-row>
      <v-col>
        <ReviewField
          data-testid="selected-patient-sex"
          :is-radio-button="true"
          :display-value="patientSexName"
        >
          Patient's sex
        </ReviewField>
      </v-col>

      <v-col>
        <ReviewField
          data-testid="selected-date-of-birth"
          :display-value="dateOfBirthDisplayText"
        >
          Patient's birth year
        </ReviewField>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'
import ReviewField from '../shared/ReviewField'
import FormSectionDivider from '../shared/FormSectionDivider'
import format from 'date-fns/format'
import { enGB } from 'date-fns/locale'

export default {
  name: 'PatientInfoOverview',
  components: { FormSectionDivider, ReviewField },
  mixins: [trialSettingsGettersMixin, newPatientGettersMixin],
  props: {
    showSectionDivider: { type: Boolean, required: false, default: true },
  },
  computed: {
    patientSexName() {
      switch (this.newPatientInfo.patientSex) {
        case 'f':
          return 'Female'
        case 'm':
          return 'Male'
        default:
          return 'Other'
      }
    },
    dateOfBirthDisplayText() {
      let dateFormat = 'd MMM yyyy'
      let day = this.newPatientInfo.birthDay
      let month = this.newPatientInfo.birthMonth
      let year = this.newPatientInfo.birthYear

      if (!year && !month && !day) return '?'

      if (!day) dateFormat = 'MMM yyyy'

      if (!month) dateFormat = 'yyyy'

      const dayIndex = day ?? 1
      const monthIndex = month ? month - 1 : 0 // month start from index 0 in JS

      return format(new Date(year, monthIndex, dayIndex), dateFormat, {
        locale: enGB,
      })
    },
  },
}
</script>

<template>
  <div>
    <FormLabel>
      <slot />
    </FormLabel>
    <div class="d-flex align-center justify-start">
      <div v-if="hasPrependSlot" class="mr-2">
        <slot name="prepend" />
      </div>
      <div
        class="review-field rounded-lg d-flex px-4 flex-grow-1"
        :class="{
          'justify-end': isDoseValue || alignRight,
          derived: isDerived,
          dense: dense,
        }"
      >
        <div v-if="isRadioButton" class="align-self-center mr-2">
          <v-icon>mdi-radiobox-marked</v-icon>
        </div>

        <div class="align-self-center" data-testid="review-field">
          {{ displayValue }}
        </div>
        <div v-if="dosingDisplayUnit" class="align-self-center pl-2">
          <slot name="append">{{ dosingDisplayUnit }}</slot>
        </div>
      </div>
      <div v-if="hasAppendOuterSlot" class="ml-2">
        <slot name="outer" />
      </div>
    </div>
    <div v-if="isDerived" class="hint">
      The dose is determined by the weekly dose
      <DoubleDummyTitrationTable :trial-site-no="trialSiteNo" />
    </div>
  </div>
</template>

<script>
import FormLabel from './FormLabel'
import DoubleDummyTitrationTable from '@/components/prescription/shared/_DoubleDummyTitrationTable.vue'

export default {
  name: 'ReviewField',
  components: { FormLabel, DoubleDummyTitrationTable },
  props: {
    displayValue: {
      required: true,
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
    isRadioButton: { type: Boolean, required: false, default: false },
    isDoseValue: { type: Boolean, required: false, default: false },
    isDerived: { type: Boolean, required: false, default: false },
    alignRight: { type: Boolean, required: false, default: false },
    trialSiteNo: { type: String, required: false },
    dense: { type: Boolean, required: false, default: false },
    dosingDisplayUnit: { type: String, required: false },
  },
  computed: {
    hasAppendOuterSlot() {
      return !!this.$slots.outer
    },
    hasPrependSlot() {
      return !!this.$slots.prepend
    },
  },
}
</script>

<style lang="scss" scoped>
.review-field {
  font-weight: bold;
  color: $nn-granite-gray;
  background-color: #f1f2f3;
  height: 48px;
  border: 1px solid #cccccc;

  &.dense {
    height: 32px;
    font-size: 14px;
  }
}

.derived {
  background-color: $nn-SB_T98;
  border: 1px solid $nn-SB_T90;
}

.hint {
  padding: 8px 12px;
  font-size: 12px;
}
</style>

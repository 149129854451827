<template>
  <div>
    <StatusDot :color="patientAppState.dotColor" />
    {{ patientAppState.textShort }}
  </div>
</template>

<script>
import appStateMixin from './patient/appStateMixin'
import StatusDot from './patientSummary/_StatusDot'

export default {
  components: { StatusDot },
  mixins: [appStateMixin],
  name: 'AppStatus',
}
</script>

<style scoped></style>

import dosingDayOptions from '@/constants/dosingDayOptions'
import newPatientActionsMixin from '../newPatientActionsMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'
import treatmentSettingsGettersMixin from '@/components/mixins/store/treatmentSettingsGettersMixin'
import treatmentSettingsActionsMixin from '@/components/mixins/store/treatmentSettingsActionsMixin'
import prescriptionContext from '@/constants/prescriptionContext'

export default {
  mixins: [
    newPatientGettersMixin,
    newPatientActionsMixin,
    treatmentSettingsGettersMixin,
    treatmentSettingsActionsMixin,
  ],
  props: {
    doseRule: { type: Object, required: true },
    context: {
      type: String,
      require: false,
      default: prescriptionContext.NEW_PATIENT,
    },
  },
  data() {
    return {
      dosingDayOptions,
    }
  },
  computed: {
    prescription() {
      return this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientPrescription(this.doseRule.prescriptionConfigurationId)
        : this.treatmentSettingsPrescription(
            this.doseRule.prescriptionConfigurationId
          )
    },
    dosingDay() {
      return this.prescription.dosingDay
    },
  },
  methods: {
    onCarbCountInput(timepoint, object) {
      this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientUpdateCarbCountValues({
            id: this.doseRule.prescriptionConfigurationId,
            key: timepoint,
            object,
          })
        : this.treatmentSettingsUpdateCarbCountValues({
            id: this.doseRule.prescriptionConfigurationId,
            key: timepoint,
            object,
          })
    },
    onInput(value, timepoint) {
      this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientUpdatePrescriptionValue({
            id: this.doseRule.prescriptionConfigurationId,
            value: value,
            key: timepoint,
          })
        : this.treatmentSettingsUpdatePrescriptionValue({
            id: this.doseRule.prescriptionConfigurationId,
            value: value,
            key: timepoint,
          })
    },
    onChange(value, name) {
      this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientUpdatePrescriptionAuditLog({
            id: this.doseRule.prescriptionConfigurationId,
            audit: {
              name,
              value,
            },
          })
        : this.treatmentSettingsUpdatePrescriptionAuditLog({
            id: this.doseRule.prescriptionConfigurationId,
            audit: {
              name,
              value,
            },
          })
    },
    dosingDisplayUnit(timepoint) {
      return this.doseRule.doseConfiguration.find?.(
        x => x.timepoint === timepoint
      )?.dosingDisplayUnit
    },
    getValidationRules(timepoint) {
      return this.doseRule.doseConfiguration.find?.(
        x => x.timepoint === timepoint
      )?.allowedDoseRange
    },
    getDefaultInitialDose(timepoint) {
      return this.doseRule.doseConfiguration.find?.(
        x => x.timepoint === timepoint
      )?.defaultInitialDose
    },
    handleBloodGlucoseUnitChange(value) {
      this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientUpdatePrescriptionBloodGlucoseUnit({
            id: this.doseRule.prescriptionConfigurationId,
            value,
          })
        : this.treatmentSettingsUpdatePrescriptionBloodGlucoseUnit({
            id: this.doseRule.prescriptionConfigurationId,
            value,
          })
    },
    onToggle(enabled, name) {
      this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientUpdateInitialDoseToggle({
            id: this.doseRule.prescriptionConfigurationId,
            name,
            enabled,
          })
        : this.treatmentSettingsUpdateInitialDoseToggle({
            id: this.doseRule.prescriptionConfigurationId,
            name,
            enabled,
          })
    },
    onChangeWeeklyDosingDay(value) {
      if (this.context === prescriptionContext.NEW_PATIENT) {
        this.newPatientUpdatePrescriptionDosingDay({
          id: this.doseRule.prescriptionConfigurationId,
          value,
        })
        this.newPatientUpdatePrescriptionAuditLog({
          id: this.doseRule.prescriptionConfigurationId,
          audit: {
            name: 'PRESCRIBE_DOSE:DOSING_DAY',
            value,
          },
        })
      } else {
        this.treatmentSettingsUpdatePrescriptionDosingDay({
          id: this.doseRule.prescriptionConfigurationId,
          value,
        })
        this.treatmentSettingsUpdatePrescriptionAuditLog({
          id: this.doseRule.prescriptionConfigurationId,
          audit: {
            name: 'PRESCRIBE_DOSE:DOSING_DAY',
            value,
          },
        })
      }
    },
  },
}


import Vue from 'vue'
import { debounce } from 'debounce'

import BirthDateInput from './_BirthDateInput.vue'
import FormLabel from '../shared/FormLabel.vue'
import FormSectionDivider from '../shared/FormSectionDivider.vue'

import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'
import newPatientActionsMixin from '../newPatientActionsMixin'
import loadingState from '@/constants/loadingState'
import service, {
  PatientAddCheckResponse,
} from '@/services/site-patient-service'
import isNumeric from '@/utils/isNumeric'
import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'

export default Vue.extend({
  name: 'PatientInfoForm',
  mixins: [
    trialSettingsGettersMixin,
    newPatientGettersMixin,
    newPatientActionsMixin,
    allowNumbersOnlyMixin,
  ],
  components: { FormSectionDivider, FormLabel, BirthDateInput },
  props: {
    siteNo: { type: String, required: true },
    createPatientError: {
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
  },
  data() {
    return {
      valid: false,
      loadingState: loadingState.INITIAL,
      sexOptions: [
        { value: 'f', text: 'Female' },
        { value: 'm', text: 'Male' },
      ],
      patientNumberRules: [v => !!v || 'Patient ID is required'],
      patientSexRules: [v => !!v || 'Patient sex is required'],
      treatmentArmRules: [v => !!v || 'Treatment arm is required'],
      dosingRegimenRules: [v => !!v || 'Bolus regimen is required'],
      apiPatientNumberError: null,
      lockRegimentSelector: false,
    }
  },
  computed: {
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    showOrSetTreatmentArm() {
      if (this.trialTreatmentArms?.length === 1) {
        this.newPatientUpdateInfo({
          treatmentArm: this.trialTreatmentArms[0].id,
        })
        this.onChangeTreatmentArm('treatmentArm', this.trialTreatmentArms[0].id)
      }
      return this.showTreatmentArm // from mixin
    },
    patientNumberErrors() {
      let v = this.newPatientInfo?.patientNumber
      if (!v) return []

      if (v.length > 2 && !v.startsWith(this.siteNo))
        return [`The patient ID must start with ${this.siteNo}`]

      if (!isNumeric(v) || v.length !== 6)
        return ['The patient ID must be 6 digits']

      if (v && this.apiPatientNumberError === v) {
        return [
          `A patient with ID ${this.apiPatientNumberError} already exists`,
        ]
      } else if (
        v &&
        this.createPatientError &&
        this.createPatientError === v
      ) {
        return [`A patient with ID ${this.createPatientError} already exists`]
      }

      return []
    },
    treatmentArmOptions() {
      return this.trialTreatmentArms.map(arm => ({
        value: arm.id,
        text: arm.displayName,
      }))
    },
  },
  methods: {
    reset() {
      this.$refs.form.resetValidation()
      this.newPatientResetInfo()
      this.newPatientSetDefaultTreatmentArm()
    },
    nextStep() {
      this.$emit('next-step', true)
    },
    onUpdatePatientNumber(event) {
      this.checkForDuplicatedPatientNumber()
      this.newPatientUpdateInfo({ patientNumber: event })
    },
    onAuditPatientNumber(value) {
      if (!this.patientNumberErrors?.length) {
        this.newPatientUpdateInfoAuditLog({
          name: 'PATIENT_INFO:PATIENT_NUMBER',
          value,
        })
      }
    },
    onChangePatientSex(name, value) {
      this.newPatientUpdateInfo({ [name]: value })
      this.newPatientUpdateInfoAuditLog({
        name: 'PATIENT_INFO:PATIENT_SEX',
        value,
      })
    },
    onChangeDosingRegimen(value) {
      this.newPatientUpdateInfo({ dosingRegimen: value })
      this.newPatientUpdateInfoAuditLog({
        name: 'PATIENT_INFO:DOSING_REGIMEN',
        value,
      })
    },
    onChangeTreatmentArm(name, value) {
      const arm = this.trialTreatmentArms.find(arm => arm.id === value)
      const featureFlags = !arm.spaFlags
        ? {
            prescriptionEnabled: true, // for backwards compatibility
          }
        : arm.spaFlags

      this.newPatientUpdateFeatureFlags(featureFlags)

      if (this.showDosingRegimens) {
        var allRegiments = []
        arm.treatments.forEach(e => {
          allRegiments = [...e.dosingRegimens]
        })

        this.lockRegimentSelector = allRegiments.length === 1
        this.newPatientUpdateInfo({
          dosingRegimen: this.lockRegimentSelector ? allRegiments[0] : null,
        })
      }

      this.newPatientUpdateInfo({ [name]: value })
      this.newPatientUpdateInfoAuditLog({
        name: 'PATIENT_INFO:TREATMENT_ARM',
        value,
      })
    },
    checkForDuplicatedPatientNumber() {
      const errors = this.patientNumberErrors
      if (errors?.length > 0) return
      if (!this.newPatientInfo.patientNumber) return

      this.loadingState = loadingState.LOADING

      let requestedPatientNumber = this.newPatientInfo.patientNumber

      service
        .checkPatientNo(this.siteNo, requestedPatientNumber)
        .then((response: PatientAddCheckResponse) => {
          if (requestedPatientNumber !== this.newPatientInfo.patientNumber) {
            // A newer request is happening, abandon this response
            return
          }

          if (!response.patientNumberAvailable) {
            this.apiPatientNumberError = this.newPatientInfo.patientNumber
          }
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          if (requestedPatientNumber !== this.newPatientInfo.patientNumber) {
            // A newer request is happening, abandon this error
            return
          }

          this.loadingState = loadingState.LOAD_ERRORED

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.checkForDuplicatedPatientNumber = debounce(
      this.checkForDuplicatedPatientNumber,
      100
    )
  },
})

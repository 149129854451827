<template>
  <div class="px-10" data-testid="patients-overview-page">
    <Breadcrumbs />
    <v-row>
      <v-col sm="8" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ trialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="trial-title">Patients</h4>
        <slot name="sub-header" />
      </v-col>
      <v-col sm="4" class="text-right">
        <AddNewPatient
          v-if="isUserAllowedToManagePatient"
          ref="addPatient"
          :siteNo="siteNo"
          @toggle="addPatientActive = $event"
          @patient-added="patientAdded"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="rounded-lg">
          <v-data-table
            data-testid="patients-data-table"
            :headers="getTableHeaders()"
            :items="items"
            @click:row="handleRowClick"
            class="rounded-lg"
            :hide-default-header="!hasData"
            :class="{ 'no-data-table': !hasData && !isLoading }"
            :hide-default-footer="!hasData && !isLoading"
            :footer-props="{
              'items-per-page-options': itemsPerPageOptions,
            }"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                v-if="hasData || isLoading"
                data-testid="patients-top-pagination"
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="itemsPerPageOptions"
                class="top-footer-item"
              />
            </template>
            <template #item.patientNumber="{ item }">
              {{ item.patientNumber }}
            </template>
            <template #item.treatmentArmId="{ item }">
              {{ trialTreatmentName(item.treatmentArmId) }}
            </template>
            <template #item.currentState="{ item }">
              <v-chip small :color="getPatientStatusColor(item.currentState)">
                {{ $t('patient-trial-status.' + item.currentState) }}
              </v-chip>
            </template>
            <template #item.currentAppState="{ item }">
              <AppStatus :app-state="item.currentAppState" />
            </template>
            <template #no-data>
              <template v-if="isLoading">Loading... Please wait</template>

              <div v-else class="py-6">
                <div class="d-flex align-center justify-center mx-auto my-4">
                  <ImgHypoHcp />
                </div>

                <strong class="no-data-text d-block mb-4"
                  >There are currently no patients created for this
                  site.</strong
                >
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <ConfirmClose ref="confirmClose">
      <template #title>Exiting the configuration</template>
      <template #btn-close>Exit configuration</template>
      <p>
        Exiting the Configuration at this point means that
        <strong>all the information you have filled in will be lost.</strong>
      </p>

      <p>Do you want to exit?</p>
    </ConfirmClose>
  </div>
</template>

<script>
import Vue from 'vue'

import AddNewPatient from '@/components/patient/add-patient/AddNewPatient.vue'
import ConfirmClose from '@/components/ConfirmClose.vue'
import Breadcrumbs from '@/components/layout/Breadcrumbs.vue'

import service from '@/services/site-patient-service'
import loadingState from '@/constants/loadingState'
import itemsPerPageOptions from '@/constants/itemsPerPageOptionsPagination'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import userGettersMixin from '@/components/mixins/store/userGettersMixin'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import globalLoadingActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import AppStatus from '@/components/AppStatus'

export default {
  name: 'PatientsOverview',
  mixins: [
    trialSettingsGettersMixin,
    userGettersMixin,
    permissionsGettersMixin,
    globalLoadingActionsMixins,
  ],
  components: {
    AppStatus,
    ConfirmClose,
    AddNewPatient,
    Breadcrumbs,
    ImgHypoHcp: () => import('@/components/images/ImgHypoHcp.vue'),
  },
  data() {
    return {
      itemsPerPageOptions,
      addPatientActive: false,
      loadingState: loadingState.INITIAL,
      items: [],
    }
  },
  props: { siteNo: { type: [String, Number], required: true } },
  computed: {
    isLoading() {
      return this.loadingState === loadingState.LOADING
    },
    hasData() {
      return this.items.length > 0
    },
    headers() {
      var list = [
        {
          text: 'Patient ID',
          align: 'start',
          sortable: true,
          value: 'patientNumber',
        },
        { text: 'Treatment arm', value: 'treatmentArmId' },
        { text: 'Birth year', value: 'birthYear' },
        { text: 'Patient status', value: 'currentState' },
        { text: 'App status', value: 'currentAppState' },
      ]

      return this.trialTreatmentArms?.length === 1
        ? list.filter(x => x.value !== 'treatmentArmId')
        : list
    },
  },
  methods: {
    getTableHeaders() {
      if (!this.showTreatmentArm) {
        return this.headers.filter(x => x.value !== 'treatmentArmId')
      }
      return this.headers
    },
    getPatientStatusColor(status) {
      switch (status) {
        case 'completed':
          return '#E6E6E6'
        case 'in_follow_up':
          return '#D8EAF8'
        case 'activated':
          return '#EEF6F6'
      }
    },
    handleRowClick(value) {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()
      this.$router.push({
        name: 'patient',
        params: { patientNo: value.patientNumber, siteNo: this.siteNo },
      })
      this.globalStopLoadingRequested()
    },
    fetchPatientList() {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()
      service
        .getPatientList(this.siteNo)
        .then(response => {
          this.loadingState = loadingState.LOAD_SUCCEEDED
          this.items = response.data
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.items = []
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
    patientAdded() {
      this.fetchPatientList()
    },
  },
  created() {
    this.fetchPatientList()
  },
  beforeRouteLeave(to, from, next) {
    if (this.addPatientActive) {
      if (this.$refs.addPatient.guardOnNavigation()) {
        this.$refs.confirmClose.open().then(confirm => {
          if (confirm) {
            this.$refs.addPatient.closeDialog()
          }
        })
      } else {
        this.$refs.addPatient.closeDialog()
      }
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style lang="scss" scoped>
.page-title {
  color: $nn-D_T20;
}

.v-data-table {
  .top-footer-item {
    border-top: none;
  }
  ::v-deep thead th {
    background-color: $nn-TB_T98;
    letter-spacing: -0.02em;
    color: $nn-D_T20 !important;
    height: 37px !important;
    border-bottom: none !important;
    > span {
      display: inline-flex;
      height: 100%;
      align-items: center;
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{key:"form",ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"font-weight-bold my-2 pb-3"},[_vm._v(_vm._s(_vm.showOrSetTreatmentArm ? 'Enter ID and treatment arm' : 'Enter ID'))]),_c('v-card-subtitle',{staticClass:"font-weight-bold font-size-14"},[_vm._v(" Make sure the patient's details match those in IWRS. ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-sm-6"},[_c('FormLabel',[_vm._v(" Patient ID ")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"value":_vm.newPatientInfo.patientNumber,"rules":_vm.patientNumberRules,"error-messages":_vm.patientNumberErrors,"placeholder":"Patient ID","required":"","outlined":"","autofocus":"","inputmode":"numeric","loading":_vm.isLoading,"data-testid":"input-patient-number"},on:{"input":_vm.onUpdatePatientNumber,"change":function($event){return _vm.onAuditPatientNumber($event)},"keypress":function($event){return _vm.allowNumbersOnly($event)}}})],1),(_vm.showDosingRegimens)?_c('v-col',{staticClass:"col-sm-6"}):_vm._e(),(_vm.showOrSetTreatmentArm)?_c('v-col',{attrs:{"data-testid":"select-treatment-arm-group"}},[_c('FormLabel',[_vm._v(" Choose treatment arm ")]),_c('v-radio-group',{staticClass:"boxed-radio-group",attrs:{"value":_vm.newPatientInfo.treatmentArm,"rules":_vm.treatmentArmRules,"hide-details":true},on:{"change":function($event){return _vm.onChangeTreatmentArm('treatmentArm', $event)}}},_vm._l((_vm.treatmentArmOptions),function(option,index){return _c('span',{key:index,staticClass:"radio-option",class:{
              selected: _vm.newPatientInfo.treatmentArm === option.value,
            }},[_c('v-radio',{attrs:{"label":option.text,"value":option.value,"data-testid":`patient-treatment-option-${option.value}`}})],1)}),0)],1):_vm._e(),(_vm.showDosingRegimens)?_c('v-col',[_c('FormLabel',[_vm._v(" Bolus regimen ")]),_c('v-radio-group',{staticClass:"boxed-radio-group",attrs:{"value":_vm.newPatientInfo.dosingRegimen,"rules":_vm.dosingRegimenRules,"hide-details":true,"disabled":_vm.lockRegimentSelector},on:{"change":function($event){return _vm.onChangeDosingRegimen($event)}}},_vm._l((_vm.trialDosingRegimens),function(option,index){return _c('span',{key:index,staticClass:"radio-option",class:{
              selected: _vm.newPatientInfo.dosingRegimen === option,
            }},[_c('v-radio',{attrs:{"label":_vm.$t(`dosing-regimen.${option}`),"value":option,"data-testid":`patient-regimen-option-${option}`}})],1)}),0)],1):_vm._e()],1),_c('FormSectionDivider',{attrs:{"use-h5":true}},[_vm._v("Enter sex and birth year")]),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('FormLabel',[_vm._v(" Patient's sex ")]),_c('v-radio-group',{staticClass:"boxed-radio-group",attrs:{"value":_vm.newPatientInfo.patientSex,"rules":_vm.patientSexRules,"data-testid":"select-patient-sex","hide-details":true},on:{"change":function($event){return _vm.onChangePatientSex('patientSex', $event)}}},_vm._l((_vm.sexOptions),function(option,index){return _c('span',{key:index,staticClass:"radio-option",class:{
              selected: _vm.newPatientInfo.patientSex === option.value,
            }},[_c('v-radio',{attrs:{"label":option.text,"value":option.value,"data-testid":`patient-sex-option-${option.value}`}})],1)}),0)],1),_c('v-col',{staticClass:"pb-0"},[_c('BirthDateInput')],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right pt-0"},[_c('v-btn',{staticClass:"elevation-1",attrs:{"color":"secondary","rounded":"","disabled":!_vm.valid,"loading":_vm.isLoading,"type":"submit","data-testid":"btn-continue"},on:{"click":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[_vm._v(" Continue ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
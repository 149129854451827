<template>
  <v-row class="app-settings-overview">
    <v-col>
      <ReviewField :display-value="languageText">
        Select language for patient app
      </ReviewField>
    </v-col>
    <v-col>
      <ReviewField :display-value="timeFormatText" :is-radio-button="true">
        Select the time format for the patient app
      </ReviewField>
    </v-col>
  </v-row>
</template>

<script>
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'
import newPatientGettersMixin from '../newPatientGettersMixin'
import trialLanguagesMixin from '../../trialLanguagesMixin'
import ReviewField from '../shared/ReviewField'

export default {
  name: 'AppSettingsOverview',
  components: { ReviewField },
  mixins: [newPatientGettersMixin, trialLanguagesMixin],
  computed: {
    languageText() {
      if (!this.appLanguageOptions) return this.newPatientAppSettings.language

      const found = this.appLanguageOptions.find(
        o => o.languageTag === this.newPatientAppSettings.language
      )

      return found ? found.name : this.newPatientAppSettings.language
    },
    timeFormatText() {
      if (!appTimeFormatOptions) return this.newPatientAppSettings.timeFormat

      const found = appTimeFormatOptions.find(
        o => o.value === this.newPatientAppSettings.timeFormat
      )

      return found ? found.text : this.newPatientAppSettings.timeFormat
    },
  },
}
</script>

<template>
  <v-toolbar dark flat color="primary" height="56">
    <BoxedDiv class="d-flex align-center justify-space-between">
      <v-toolbar-title :data-testid="dataTestid">
        <slot />
      </v-toolbar-title>

      <v-btn icon dark @click="closeDialog" data-testid="dialog-btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </BoxedDiv>
  </v-toolbar>
</template>

<script>
import BoxedDiv from '@/components/layout/BoxedDiv'
export default {
  name: 'BoxedToolbar',
  components: { BoxedDiv },
  props: {
    dataTestid: { type: String, required: true },
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog', true)
    },
  },
}
</script>

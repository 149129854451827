<template>
  <v-row>
    <v-col sm="2" class="pt-0">
      <div class="patient-summary-label">Patient ID</div>
      <strong data-testid="summary-patient-id">{{
        newPatientInfo.patientNumber
      }}</strong>
    </v-col>
    <v-col v-if="showTreatmentArm" class="pt-0">
      <div class="patient-summary-label">Treatment arm</div>
      <strong data-testid="summary-treatment-arm">{{
        treatmentArmDisplayName
      }}</strong>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'

export default {
  name: 'PatientSummary',
  mixins: [trialSettingsGettersMixin, newPatientGettersMixin],
}
</script>

<style lang="scss" scoped>
.patient-summary-label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
</style>

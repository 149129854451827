<template>
  <v-form v-model="valid" ref="form">
    <v-card-title class="font-weight-bold mt-2 pb-3">
      Prescribe initial dose
    </v-card-title>
    <v-card-text>
      <PatientSummary class="mt-4 mb-5" />
      <InitialDoses
        :isDoseRulesLoading="isDoseRulesLoading"
        :site-no="siteNo"
      />
      <v-row>
        <v-col class="text-right">
          <v-btn rounded @click="prevStep" class="mx-5" data-testid="btn-back"
            >Back</v-btn
          >
          <v-btn
            color="secondary"
            rounded
            @click.prevent="nextStep"
            autofocus
            type="submit"
            :disabled="!allowNextStep"
            data-testid="btn-continue"
            :loading="isDoseRulesLoading"
          >
            Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import PatientSummary from '../patient-info/PatientSummary'
import InitialDoses from './InitialDoses'

export default {
  name: 'AddInitialDose',
  components: {
    InitialDoses,
    PatientSummary,
  },
  props: {
    isDoseRulesLoading: { type: Boolean, default: false },
    siteNo: { type: String, required: true },
  },
  data() {
    return {
      valid: false,
    }
  },
  computed: {
    allowNextStep() {
      return this.valid
    },
  },
  methods: {
    nextStep() {
      this.$emit('next-step', true)
    },
    prevStep() {
      this.$emit('prev-step', true)
    },
  },
}
</script>

<template>
  <label
    class="config-form-label"
    :class="[{ small: small }, { large: large }]"
  >
    <slot />
  </label>
</template>

<script>
export default {
  name: 'FormLabel',
  props: {
    small: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
  },
}
</script>

<style lang="scss" scoped>
.config-form-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
  letter-spacing: -0.02em;
  font-weight: 400;
  color: $nn-D_T20;
}
.small {
  font-size: 12px !important;
}
.large {
  font-size: 16px !important;
}
</style>

<template>
  <v-row class="form-section-divider no-gutters">
    <v-col>
      <v-divider />
      <div
        :class="[
          { 'divider-heading-h4': useH4 },
          { 'divider-heading-h5': useH5 },
        ]"
      >
        <slot />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormSectionDivider',
  props: {
    useH4: { type: Boolean, required: false, default: false },
    useH5: { type: Boolean, required: false, default: false },
  },
}
</script>

<style lang="scss" scoped>
.v-divider {
  margin: 20px 0 24px 0;
}

.divider-heading-h5 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: $nn-D_T10;
}

.divider-heading-h4 {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: $nn-black;
}
</style>

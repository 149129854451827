<template>
  <v-row>
    <v-col v-if="prescription.bolus_breakfast.enabled" sm="3" class="pt-0">
      <ReviewField
        :display-value="prescription.bolus_breakfast.value"
        :dosing-display-unit="dosingDisplayUnit('bolus_breakfast')"
        data-testid="bolus-breakfast-value"
        :is-dose-value="true"
      >
        {{ $t('time-point.bolus_breakfast') }}
      </ReviewField>
    </v-col>

    <v-col v-if="prescription.bolus_lunch.enabled" sm="3" class="pt-0">
      <ReviewField
        :display-value="prescription.bolus_lunch.value"
        :dosing-display-unit="dosingDisplayUnit('bolus_lunch')"
        data-testid="bolus-lunch-value"
        :is-dose-value="true"
      >
        {{ $t('time-point.bolus_lunch') }}
      </ReviewField>
    </v-col>

    <v-col v-if="prescription.bolus_dinner.enabled" sm="3" class="pt-0">
      <ReviewField
        :display-value="prescription.bolus_dinner.value"
        :dosing-display-unit="dosingDisplayUnit('bolus_dinner')"
        data-testid="bolus-dinner-value"
        :is-dose-value="true"
      >
        {{ $t('time-point.bolus_dinner') }}
      </ReviewField>
    </v-col>

    <v-col
      v-if="prescription.bolus_extra_prescription.enabled"
      sm="3"
      class="pt-0"
    >
      <ReviewField
        :display-value="prescription.bolus_extra_prescription.value"
        :dosing-display-unit="dosingDisplayUnit('bolus_extra_prescription')"
        data-testid="bolus-extra-value"
        :is-dose-value="true"
      >
        {{ $t('time-point.bolus_extra_prescription') }}
      </ReviewField>
    </v-col>
  </v-row>
</template>

<script>
import initialPrescriptionMixin from './initialPrescriptionMixin'
import ReviewField from '../shared/ReviewField'

export default {
  name: 'BolusOverview',
  mixins: [initialPrescriptionMixin],
  components: { ReviewField },
}
</script>

<style scoped></style>

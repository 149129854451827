<template>
  <v-form v-model="valid" ref="form" class="app-settings">
    <v-card-title class="font-weight-bold mt-2 pb-3"
      >Configure patient app</v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col>
          <FormLabel> Select language for patient app </FormLabel>
          <v-autocomplete
            :items="appLanguageOptionsSorted"
            item-text="name"
            item-value="languageTag"
            :value="newPatientAppSettings.language"
            @input="onUpdateLanguage"
            @change="onAuditLanguage($event)"
            outlined
            placeholder="Please select"
            :rules="appLanguageRules"
            data-testid="app-language"
            class="rounded-lg"
            :menu-props="{
              maxHeight: 400,
            }"
          />
        </v-col>

        <v-col>
          <FormLabel> Select the time format for the patient app </FormLabel>

          <v-radio-group
            :value="newPatientAppSettings.timeFormat"
            @change="onChangeTimeFormat"
            :rules="appTimeFormatRules"
            data-testid="select-app-time-format"
            class="boxed-radio-group"
            ><span
              v-for="(option, index) in appTimeFormatOptions"
              :key="index"
              class="radio-option"
              :class="{
                selected: newPatientAppSettings.timeFormat === option.value,
              }"
            >
              <v-radio
                :label="option.text"
                :value="option.value"
                :data-testid="`app-time-format-option-${option.value}`"
              />
            </span>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn rounded @click="prevStep" class="mx-5" data-testid="btn-back"
            >Back</v-btn
          >
          <v-btn
            color="secondary"
            rounded
            @click.prevent="nextStep"
            autofocus
            type="submit"
            :disabled="!valid"
            data-testid="btn-continue"
          >
            Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import trialLanguagesMixin from '../../trialLanguagesMixin'
import appTimeFormatOptions from '@/constants/appTimeFormatOptions'
import newPatientGettersMixin from '../newPatientGettersMixin'
import newPatientActionsMixin from '../newPatientActionsMixin'
import FormLabel from '../shared/FormLabel'

export default {
  name: 'AppSettings',
  components: { FormLabel },
  mixins: [newPatientGettersMixin, newPatientActionsMixin, trialLanguagesMixin],
  data() {
    return {
      valid: false,
      appLanguage: null,
      appTimeFormat: null,
      appTimeFormatOptions,
      appLanguageRules: [v => !!v || 'App language is required'],
      appTimeFormatRules: [v => !!v || 'Time format is required'],
    }
  },
  methods: {
    reset() {
      this.$refs.form.resetValidation()
      this.newPatientResetAppSettings()
    },
    nextStep() {
      this.$emit('next-step', true)
    },
    prevStep() {
      this.$emit('prev-step', true)
    },
    onUpdateLanguage(language) {
      this.newPatientUpdateAppSettings({ language })
    },
    onAuditLanguage(value) {
      this.newPatientUpdateAppSettingsAuditLog({
        name: 'APP_SETTINGS:LANGUAGE',
        value,
      })
    },
    onChangeTimeFormat(timeFormat) {
      this.newPatientUpdateAppSettings({ timeFormat })
      this.newPatientUpdateAppSettingsAuditLog({
        name: 'APP_SETTINGS:TIME_FORMAT',
        value: timeFormat,
      })
    },
  },
}
</script>

<template>
  <v-row no-gutters>
    <v-col sm="8">
      <ol>
        <li>Pick up the patient's iPhone</li>
        <li>Select the trial app icon</li>
        <li>Follow the instructions in the app</li>
        <li>Use the app to scan the QR code on this page</li>
      </ol>
    </v-col>
    <v-col sm="4">
      <v-scroll-y-transition hide-on-leave>
        <v-skeleton-loader
          v-if="loading"
          width="175"
          height="175"
          type="image"
          class="float-right"
        />
        <QrCode v-else class="float-right" :value="dataForQrCode" />
      </v-scroll-y-transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AppQrCode',
  components: {
    QrCode: () => import('@/components/patient/app/_QrCode'),
  },
  props: {
    loading: { type: Boolean, required: true },
    dataForQrCode: {
      default: null,
      validator: prop => typeof prop === 'string' || prop === null,
    },
  },
}
</script>
<style lang="scss" scoped>
ol {
  counter-reset: li;
  margin-left: 0;
  padding-left: 0;
}
ol > li {
  position: relative;
  margin: 0 0 16px 2em;
  padding: 4px 8px;
  list-style: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: -0.02em;
}
ol > li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 2px;
  left: -32px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  padding: 2px;

  border: 1px solid $nn-D_T20;
  border-radius: 50%;
  color: $nn-D_T20;
  font-size: 12px;
  text-align: center;
}
li ol,
li ul {
  margin-top: 6px;
}
ol ol li:last-child {
  margin-bottom: 0;
}
</style>

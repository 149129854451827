<template>
  <v-card elevation="0" class="app-setup-done">
    <v-card-text class="text-center">
      <v-row>
        <v-spacer></v-spacer>
        <ImgAppSetupSuccess />
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <h4 class="mt-4">Success!</h4>
          The patient app and the<br />
          ePID Dashboard are connected!
        </v-col>
      </v-row>

      <v-row v-if="newPatientIsProEnabled && proAvailable">
        <v-col class="text-right font-italic">
          You are now ready to send PROs
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn
            rounded
            class="elevation-1 mx-3"
            @click.prevent="nextStep"
            type="submit"
            data-testid="btn-continue"
          >
            Go to patient
          </v-btn>
          <v-btn
            v-if="newPatientIsProEnabled && proAvailable"
            color="secondary"
            rounded
            class="elevation-1"
            @click.prevent="goToPro"
            type="submit"
            data-testid="btn-go-to-pro"
          >
            Go to PROs
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'

import loadingState from '@/constants/loadingState'
import service from '@/services/pro-service'
import globalProgressActionsMixins from '@/components/mixins/store/globalProgressActionsMixins'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'
import ImgAppSetupSuccess from '@/components/images/ImgAppSetupSuccess'

export default {
  name: 'AppSetupDone',
  components: { ImgAppSetupSuccess },
  computed: {
    newPatientIsProEnabled() {
      return this.newPatientFeatureFlags.prosEnabled
    },
  },
  mixins: [
    trialSettingsGettersMixin,
    newPatientGettersMixin,
    globalProgressActionsMixins,
  ],
  props: {
    patientNumber: { type: String, required: true },
  },
  data() {
    return {
      loadingState: loadingState.INITIAL,
      proAvailable: false,
    }
  },
  methods: {
    nextStep() {
      this.$emit('next-step', true)
    },
    goToPro() {
      this.$emit('go-to-pro')
    },
    fetchAvailablePros() {
      this.loadingState = loadingState.LOADING
      this.globalStartLoadingRequested()

      service
        .getProsList(this.patientNumber)
        .then(response => {
          const firstVisit = response.find(visit => visit.visitId === '2')
          const firstPros = !firstVisit ? [] : firstVisit.pros
          this.proAvailable = firstPros.length > 0
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          this.loadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.globalStopLoadingRequested()
        })
    },
  },
  created() {
    if (this.newPatientIsProEnabled) {
      this.fetchAvailablePros()
    }
  },
}
</script>

import newPatientGettersMixin from '../newPatientGettersMixin'
import treatmentSettingsGettersMixin from '@/components/mixins/store/treatmentSettingsGettersMixin'
import prescriptionContext from '@/constants/prescriptionContext'

export default {
  mixins: [newPatientGettersMixin, treatmentSettingsGettersMixin],
  props: {
    context: {
      type: String,
      require: false,
      default: prescriptionContext.NEW_PATIENT,
    },
  },
  computed: {
    doseRules() {
      return this.context === prescriptionContext.NEW_PATIENT
        ? this.newPatientDoseRules
        : this.treatmentSettingsDoseRules
    },
  },
}

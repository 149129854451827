import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'

export default {
  computed: {
    ...trialSettingsMapGetters(),
    appLanguageOptions() {
      return this.trialLanguages
    },
    appLanguageOptionsSorted() {
      return this.trialLanguages.sort((a, b) => a.name.localeCompare(b.name))
    },
  },
}


import Vue from 'vue'
import { isAfter, isValid, parseISO } from 'date-fns'

import newPatientGettersMixin from '../newPatientGettersMixin'
import newPatientActionsMixin from '../newPatientActionsMixin'
import isNumeric from '@/utils/isNumeric'
import FormLabel from '../shared/FormLabel.vue'
import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'

export default Vue.extend({
  name: 'BirthDateInput',
  components: { FormLabel },
  mixins: [
    newPatientGettersMixin,
    newPatientActionsMixin,
    allowNumbersOnlyMixin,
  ],
  props: {
    birthSetting: {
      type: Object,
      required: false,
      default: () => ({ allowDay: false, allowMonth: false, allowYear: true }),
    },
  },
  data() {
    return {
      monthOptions: [
        { value: '01', text: 'JAN' },
        { value: '02', text: 'FEB' },
        { value: '03', text: 'MAR' },
        { value: '04', text: 'APR' },
        { value: '05', text: 'MAY' },
        { value: '06', text: 'JUN' },
        { value: '07', text: 'JUL' },
        { value: '08', text: 'AUG' },
        { value: '09', text: 'SEP' },
        { value: '10', text: 'OCT' },
        { value: '11', text: 'NOV' },
        { value: '12', text: 'DEC' },
      ],
      birthYearRules: [v => !!v || 'Year of birth is required'],
    }
  },
  computed: {
    birthYearErrors() {
      if (!this.birthSetting.allowYear) return []

      let value = this.newPatientInfo.birthYear
      if (!value) return []

      const v = Number(value)

      if (!v) return ['Year must be a number']

      if (v < 1000 || v > 9999) return ['Year must be 4 digits']

      if (v < 1900 || v > new Date().getFullYear())
        return [`Year must be between 1900 and ${new Date().getFullYear()}`]

      if (this.isFutureDate) return ['Date of birth cannot be in the future']

      return []
    },
    birthMonthsErrors() {
      if (!this.birthSetting.allowMonth) return []

      let v = this.newPatientInfo.birthMonth

      if (this.newPatientInfo.birthDay && this.newPatientInfo.birthYear && !v)
        return ['Month is required when day is specified']

      return []
    },
    birthDayErrors() {
      if (!this.birthSetting.allowDay) return []

      let v = this.newPatientInfo.birthDay
      if (!v) return []

      if (!isNumeric(v)) return ['Day must be 2 digits']

      if (v.length !== 2)
        return ['Please enter day with 2 digits (eg. 02 instead of 2)']

      if (!(v > 0 && v < 32)) return ['Day must be between 01-31']

      if (!this.isRealExistingDate)
        return ['The specified date is invalid: No such day in the calendar']

      return []
    },
    getFullBirthDate() {
      if (
        this.newPatientInfo.birthYear &&
        this.newPatientInfo.birthYear.length === 4 &&
        this.newPatientInfo.birthMonth &&
        this.newPatientInfo.birthDay &&
        this.newPatientInfo.birthDay.length === 2
      ) {
        const candidate = `${this.newPatientInfo.birthYear}-${this.newPatientInfo.birthMonth}-${this.newPatientInfo.birthDay}`
        return parseISO(candidate)
      }
      return null
    },
    isFutureDate() {
      const v = this.getFullBirthDate
      if (v && isValid(v)) {
        return isAfter(v, Date.now())
      }
      return false
    },
    isRealExistingDate() {
      const v = this.getFullBirthDate
      if (v) {
        return isValid(v)
      }

      return true
    },
  },
  methods: {
    onUpdate(name, event) {
      this.newPatientUpdateInfo({ [name]: event })
    },
    onAudit(name, value) {
      if (!this.birthDayErrors?.length) {
        this.newPatientUpdateInfoAuditLog({
          name,
          value,
        })
      }
    },
  },
})

<template>
  <div>
    <template v-for="(doseRule, index) in doseRules">
      <OnceDailyOverview
        v-if="doseRule.type === prescriptionType.BASAL_ONCE_DAILY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <OnceWeeklyOverview
        v-if="doseRule.type === prescriptionType.BASAL_ONCE_WEEKLY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <DoubleDummyOverview
        v-else-if="doseRule.type === prescriptionType.DOUBLE_DUMMY"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :site-no="siteNo"
        :context="context"
      />

      <BolusOverview
        v-else-if="doseRule.type === prescriptionType.BOLUS"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />

      <BolusCarbCountOverview
        v-else-if="doseRule.type === prescriptionType.BOLUS_CARB_COUNT"
        :key="`${doseRule.prescriptionConfigurationId}_${index}`"
        :dose-rule="doseRule"
        :context="context"
      />
    </template>
  </div>
</template>

<script>
import initialDoseRulesMixin from './initialDoseRulesMixin'
import BolusOverview from './_BolusOverview'

import prescriptionType from '@/constants/prescriptionType'

export default {
  name: 'DoseOverview',
  components: {
    BolusOverview,
    DoubleDummyOverview: () => import('./_DoubleDummyOverview'),
    OnceWeeklyOverview: () => import('./_OnceWeeklyOverview'),
    OnceDailyOverview: () => import('./_OnceDailyOverview'),
    BolusCarbCountOverview: () => import('./_BolusCarbCountOverview'),
  },
  mixins: [initialDoseRulesMixin],
  props: {
    siteNo: { type: String, required: true },
  },
  data() {
    return {
      prescriptionType,
    }
  },
}
</script>

/**
 * Is the input numeric in string or number format
 * https://stackoverflow.com/a/52986361/815507
 *
 * isNumeric('0') -> true
 * isNumeric('-1.1') -> true
 * isNumeric(2.2) -> true
 * isNumeric('') -> false
 * isNumeric(null) -> false
 * isNumeric(undefined) -> false
 */
export default function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

<template>
  <div>
    <v-card-title class="font-weight-bold mt-2 pb-3">
      Review and confirm</v-card-title
    >
    <v-card-text>
      <FormSectionDivider :use-h4="true" class="mt-0 pt-0 pb-5"
        ><div class="d-flex align-center">
          Patient details
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            depressed
            @click="editPatient"
            data-testid="btn-edit-patient-details"
            class="ml-3 elevation-1"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </div>
      </FormSectionDivider>

      <PatientInfoOverview :show-section-divider="false" />

      <div v-if="newPatientIsPrescriptionEnabled">
        <FormSectionDivider :use-h4="true" class="pb-5">
          <div class="d-flex align-center">
            Initial dose prescription
            <v-btn
              color="secondary"
              fab
              x-small
              dark
              depressed
              @click="editDose"
              data-testid="btn-edit-dose"
              class="ml-3 elevation-1"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </div>
        </FormSectionDivider>

        <DoseOverview :site-no="siteNo" />
      </div>
      <FormSectionDivider :use-h4="true" class="pb-5 d-flex align-center">
        <div class="d-flex align-center">
          App configuration
          <v-btn
            color="secondary"
            fab
            x-small
            dark
            depressed
            @click="editApp"
            data-testid="btn-edit-app-settings"
            class="ml-3 elevation-1"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </div>
      </FormSectionDivider>

      <AppSettingsOverview />

      <v-row>
        <v-col class="text-right">
          <v-btn
            rounded
            @click="prevStep"
            class="mx-5"
            data-testid="btn-back"
            :disabled="isLoading"
            >Back</v-btn
          >
          <v-btn
            color="secondary"
            rounded
            @click.prevent="nextStep"
            autofocus
            type="submit"
            data-testid="btn-continue"
            :loading="isLoading"
          >
            Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import PatientInfoOverview from './patient-info/PatientInfoOverview'
import DoseOverview from './initial-dose/DoseOverview'
import AppSettingsOverview from './init-app/AppSettingsOverview'
import FormSectionDivider from './shared/FormSectionDivider'
import newPatientGettersMixin from './newPatientGettersMixin'

export default {
  name: 'ConfirmAll',
  mixins: [newPatientGettersMixin],
  components: {
    FormSectionDivider,
    AppSettingsOverview,
    DoseOverview,
    PatientInfoOverview,
  },
  computed: {
    newPatientIsPrescriptionEnabled() {
      return this.newPatientFeatureFlags.prescriptionsEnabled
    },
  },
  props: {
    isLoading: { type: Boolean, required: true },
    siteNo: { type: String, required: true },
  },
  methods: {
    editPatient() {
      this.$emit('edit-patient', true)
    },
    editDose() {
      this.$emit('edit-dose', true)
    },
    editApp() {
      this.$emit('edit-app', true)
    },
    nextStep() {
      this.$emit('next-step', true)
    },
    prevStep() {
      this.$emit('prev-step', true)
    },
  },
}
</script>

import isNumeric from '@/utils/isNumeric'

export default {
  methods: {
    allowNumbersOnly(e) {
      const char = String.fromCharCode(e.keyCode)
      if (isNumeric(char)) return true
      e.preventDefault()
    },
  },
}

<template>
  <v-card color="#F4F8FE">
    <BoxedToolbar
      @close-dialog="closeDialog"
      data-testid="add-patient-card-title"
      class="px-6"
    >
      <template v-if="titleStep === 1"> Step 1 — Patient details </template>
      <template v-if="titleStep === 2">
        Step 2 — Prescribe initial dose
      </template>
      <template v-if="titleStep === 3">
        Step 3 — Configure patient app
      </template>
      <template v-if="titleStep === 4"> Review data </template>
      <template v-if="titleStep === 5"> Scan QR code </template>
      <template v-if="titleStep === 6"> Configuration successful </template>
    </BoxedToolbar>

    <v-stepper v-model="overallStep" alt-labels>
      <v-stepper-header
        class="v-stepper__header--always-display-labels elevation-0"
      >
        <BoxedDiv class="d-flex align-center justify-space-between">
          <v-stepper-step step="1" :complete="overallStep > 1" color="secondary"
            ><span class="active-color">Patient details</span></v-stepper-step
          >

          <v-divider
            class="config-divider"
            :class="{ 'active-color': overallStep > 1 }"
          />

          <v-stepper-step
            v-if="newPatientIsPrescriptionEnabled"
            step="2"
            :complete="overallStep > 2"
            color="secondary"
          >
            <span :class="{ 'active-color': overallStep >= 2 }">
              Dose prescription</span
            ></v-stepper-step
          >

          <v-divider
            class="config-divider"
            :class="{ 'active-color': overallStep > 2 }"
          />

          <v-stepper-step
            step="3"
            :complete="currentStep === steps.DONE"
            color="secondary"
          >
            <span :class="{ 'active-color': overallStep >= 3 }">
              App configuration</span
            >
          </v-stepper-step>
        </BoxedDiv>
      </v-stepper-header>

      <v-stepper-items>
        <BoxedDiv>
          <v-stepper-content step="1" class="pt-0 pb-1">
            <PatientInfoForm
              v-if="currentStep === steps.INPUT_PATIENT_INFO"
              :site-no="siteNo"
              :create-patient-error="apiPatientNumberError"
              @next-step="gotoNextStep"
              ref="patientInfoForm"
              data-testid="patient-info-form"
            />

            <PatientInfoConfirm
              v-else-if="currentStep === steps.CONFIRM_PATIENT_INFO"
              @next-step="gotoNextStep"
              @prev-step="gotoPrevStep"
            />
          </v-stepper-content>

          <v-stepper-content step="2" class="pt-0 pb-1">
            <template v-if="isDoseRulesLoadingErrored">
              <v-row>
                <v-col>
                  <v-alert type="error" dense text>
                    Error: Could not load dose rules. Try again later.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    depressed
                    outlined
                    @click="gotoPrevStep"
                    class="mx-5"
                    data-testid="btn-back"
                    >Back</v-btn
                  >
                </v-col>
              </v-row>
            </template>

            <template v-else>
              <AddInitialDose
                v-if="currentStep === steps.PRESCRIBE_DOSE"
                @next-step="gotoNextStep"
                @prev-step="gotoPrevStep"
                ref="initialDoseForm"
                :isDoseRulesLoading="isDoseRulesLoading"
                :site-no="siteNo"
              />
            </template>
          </v-stepper-content>

          <v-stepper-content step="3" class="pt-0 pb-1">
            <AppSettings
              v-if="currentStep === steps.INPUT_APP_SETTINGS"
              @next-step="gotoNextStep"
              @prev-step="gotoPrevStep"
              ref="appSettingsForm"
            />

            <ConfirmAll
              v-if="currentStep === steps.CONFIRM_ALL"
              :is-loading="isCreatePatientLoading"
              :site-no="siteNo"
              @edit-patient="currentStep = steps.INPUT_PATIENT_INFO"
              @edit-app="currentStep = steps.INPUT_APP_SETTINGS"
              @edit-dose="currentStep = steps.PRESCRIBE_DOSE"
              @prev-step="gotoPrevStep"
              @next-step="createPatient"
            />

            <AppQrCodeCard
              v-if="
                currentStep === steps.LOADING_QR ||
                currentStep === steps.QR_LOADED
              "
              :loading="currentStep === steps.LOADING_QR"
              :dataForQrCode="dataForQrCode"
              @next-step="closeAndRedirect"
            />

            <AppSetupDone
              v-if="currentStep === steps.DONE"
              :patient-number="newPatientInfo.patientNumber"
              @next-step="closeAndRedirect"
              @go-to-pro="closeAndRedirectToPro"
            />
          </v-stepper-content>
        </BoxedDiv>
      </v-stepper-items>
    </v-stepper>

    <ConfirmClose ref="confirmClose">
      <template #title>Exiting the configuration</template>
      <template #btn-close>Exit configuration</template>
      <p>
        Exiting the Configuration at this point means that
        <strong>all the information you have filled in will be lost.</strong>
      </p>

      <p>Do you want to exit?</p>
    </ConfirmClose>
  </v-card>
</template>

<script>
import Vue from 'vue'
import service from '@/services/site-patient-service'
import prescriptionService from '@/services/prescription-service'
import loadingState from '@/constants/loadingState'
import PatientInfoForm from './patient-info/PatientInfoForm'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import PatientInfoConfirm from './patient-info/PatientInfoConfirm'
import AddInitialDose from './initial-dose/AddInitialDose'
import ConfirmAll from './_ConfirmAll'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import appService from '@/services/app-service'
import AppQrCodeCard from './init-app/AppQrCodeCard'
import AppSettings from './init-app/AppSettings'
import AppSetupDone from './init-app/AppSetupDone'
import newPatientGettersMixin from './newPatientGettersMixin'
import newPatientActionsMixin from './newPatientActionsMixin'
import isNumeric from '@/utils/isNumeric'
import ConfirmClose from '@/components/ConfirmClose'
import BoxedDiv from '@/components/layout/BoxedDiv'

import prescriptionType from '@/constants/prescriptionType'

const addPatientSteps = Object.freeze({
  INPUT_PATIENT_INFO: 1,
  CONFIRM_PATIENT_INFO: 2,
  PRESCRIBE_DOSE: 3,
  INPUT_APP_SETTINGS: 4,
  CONFIRM_ALL: 5,
  LOADING_QR: 6,
  LOAD_QR_ERRORED: 7,
  QR_LOADED: 8,
  AWAIT_DEVICE_CONFIGURATION: 9,
  DONE: 10,
})

export default Vue.extend({
  name: 'AddPatientSteps',
  mixins: [
    trialSettingsGettersMixin,
    newPatientGettersMixin,
    newPatientActionsMixin,
  ],
  components: {
    BoxedDiv,
    BoxedToolbar,
    ConfirmClose,
    AppQrCodeCard,
    AddInitialDose,
    PatientInfoForm,
    PatientInfoConfirm,
    ConfirmAll,
    AppSettings,
    AppSetupDone,
  },
  props: { siteNo: { type: String, required: true } },
  data() {
    return {
      createPatientLoadingState: loadingState.INITIAL,
      doseRulesLoadingState: loadingState.INITIAL,
      currentStep: addPatientSteps.INPUT_PATIENT_INFO,
      doseInitialLoadedFor: '',
      apiError: null,
      apiPatientNumberError: null,
      steps: addPatientSteps,
      dataForQrCode: null,
      isMounted: false,
      prescriptionType,
    }
  },
  computed: {
    titleStep: {
      get() {
        switch (this.currentStep) {
          case addPatientSteps.INPUT_PATIENT_INFO:
          case addPatientSteps.CONFIRM_PATIENT_INFO:
            return 1
          case addPatientSteps.PRESCRIBE_DOSE:
            return 2
          case addPatientSteps.CONFIRM_ALL:
            return 4
          case addPatientSteps.LOADING_QR:
          case addPatientSteps.LOAD_QR_ERRORED:
          case addPatientSteps.QR_LOADED:
          case addPatientSteps.AWAIT_DEVICE_CONFIGURATION:
            return 5
          case addPatientSteps.DONE:
            return 6
          default:
            return 3
        }
      },
    },
    overallStep: {
      get() {
        switch (this.currentStep) {
          case addPatientSteps.INPUT_PATIENT_INFO:
          case addPatientSteps.CONFIRM_PATIENT_INFO:
            return 1
          case addPatientSteps.PRESCRIBE_DOSE:
            return 2
          default:
            return 3
        }
      },
      set() {
        //do nothing
      },
    },
    isCreatePatientLoading() {
      return this.createPatientLoadingState === loadingState.LOADING
    },
    isDoseRulesLoadingErrored() {
      return this.doseRulesLoadingState === loadingState.LOAD_ERRORED
    },
    isDoseRulesLoading() {
      return this.doseRulesLoadingState === loadingState.LOADING
    },
    newPatientIsPrescriptionEnabled() {
      return this.newPatientFeatureFlags.prescriptionsEnabled
    },
  },
  methods: {
    reset() {
      this.createPatientLoadingState = loadingState.INITIAL
      this.doseRulesLoadingState = loadingState.INITIAL
      this.currentStep = addPatientSteps.INPUT_PATIENT_INFO
      this.doseInitialLoadedFor = ''
      this.newPatientClearAll()

      if (this.$refs.patientInfoForm) this.$refs.patientInfoForm.reset()
      if (this.$refs.initialDoseForm) this.$refs.initialDoseForm.reset()
      if (this.$refs.appSettingsForm) this.$refs.appSettingsForm.reset()
    },
    closeDialog() {
      if (this.guardOnNavigation()) {
        this.$refs.confirmClose.open().then(confirm => {
          if (confirm) {
            this.$emit('close-dialog', true)
            this.reset()
          }
        })
      } else {
        this.$emit('close-dialog', true)
        this.reset()
      }
    },
    closeAndRedirect() {
      const patientNumber = this.newPatientInfo.patientNumber
      this.$emit('close-dialog', true)
      this.reset()
      this.$router.push({
        name: 'patient',
        params: { patientNo: patientNumber },
      })
    },
    closeAndRedirectToPro() {
      const patientNumber = this.newPatientInfo.patientNumber
      this.$emit('close-dialog', true)
      this.reset()
      this.$router.push({
        name: 'proQuestionnaires',
        params: { patientNo: patientNumber },
      })
    },
    gotoNextStep() {
      switch (this.currentStep) {
        case addPatientSteps.INPUT_PATIENT_INFO:
          if (this.showTreatmentArm) {
            this.currentStep = addPatientSteps.CONFIRM_PATIENT_INFO
          } else if (!this.newPatientIsPrescriptionEnabled) {
            this.currentStep = addPatientSteps.INPUT_APP_SETTINGS
          } else {
            this.gotoPrescribeDose()
          }
          break
        case addPatientSteps.CONFIRM_PATIENT_INFO:
          if (!this.newPatientIsPrescriptionEnabled) {
            this.currentStep = addPatientSteps.INPUT_APP_SETTINGS
          } else {
            this.gotoPrescribeDose()
          }
          break
        case addPatientSteps.PRESCRIBE_DOSE:
          this.currentStep = addPatientSteps.INPUT_APP_SETTINGS
          break
        case addPatientSteps.INPUT_APP_SETTINGS:
          this.currentStep = addPatientSteps.CONFIRM_ALL
          break
        default:
          this.currentStep += 1
      }
    },
    gotoPrevStep() {
      switch (this.currentStep) {
        case addPatientSteps.INPUT_PATIENT_INFO:
          break
        case addPatientSteps.CONFIRM_PATIENT_INFO:
        case addPatientSteps.PRESCRIBE_DOSE:
          this.currentStep = addPatientSteps.INPUT_PATIENT_INFO
          break
        case addPatientSteps.INPUT_APP_SETTINGS:
          if (this.newPatientIsPrescriptionEnabled) {
            this.currentStep = addPatientSteps.PRESCRIBE_DOSE
          } else {
            this.currentStep = addPatientSteps.INPUT_PATIENT_INFO
          }
          break
        case addPatientSteps.CONFIRM_ALL:
          this.currentStep = addPatientSteps.INPUT_APP_SETTINGS
          break
        default:
          this.currentStep -= 1
      }
    },
    gotoPrescribeDose() {
      this.loadDoseInitials()
      this.currentStep = addPatientSteps.PRESCRIBE_DOSE
    },
    loadDoseInitials() {
      const allowCall =
        this.newPatientInfo.patientNumber &&
        this.newPatientInfo.patientSex &&
        this.newPatientInfo.birthYear &&
        this.newPatientInfo.treatmentArm &&
        this.doseInitialLoadedFor !==
          this.newPatientInfo.treatmentArm + this.newPatientInfo.dosingRegimen

      if (!allowCall) {
        return
      }

      const request = {
        siteNumber: this.siteNo,
        patientNumber: this.newPatientInfo.patientNumber,
        treatmentArmId: this.newPatientInfo.treatmentArm,
        sex: this.newPatientInfo.patientSex,
        birthDay: this.newPatientInfo.birthDay,
        birthMonth: this.newPatientInfo.birthMonth,
        birthYear: this.newPatientInfo.birthYear,
        dosingRegimen: this.newPatientInfo.dosingRegimen,
      }

      this.doseRulesLoadingState = loadingState.LOADING
      prescriptionService
        .getInitialization(request)
        .then(result => {
          this.doseRulesLoadingState = loadingState.LOAD_SUCCEEDED
          this.doseInitialLoadedFor =
            request.treatmentArmId + request.dosingRegimen
          this.newPatientUpdateDoseRules(result.data.items || [])
          if (!result.data.items || result.data.items.length === 0) {
            this.gotoNextStep()
          }
        })
        .catch(error => {
          this.doseRulesLoadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
          this.newPatientUpdateDoseRules([])
        })
    },
    createPatient() {
      this.currentStep = addPatientSteps.LOADING_QR
      const dateTimeZone = dateTimeWithTimeZone()

      let preferredDosingDay = null
      let prescriptions = []

      this.newPatientDoseRules.forEach(doseRule => {
        let prescription = {
          prescriptionConfigurationId: doseRule.prescriptionConfigurationId,
          type: doseRule.type,
          doses: [],
          bloodGlucoseUnit:
            doseRule.type === prescriptionType.BOLUS_CARB_COUNT
              ? this.newPatientPrescription(
                  doseRule.prescriptionConfigurationId
                ).bloodGlucoseUnit
              : null,
        }

        doseRule.doseConfiguration.forEach(initialDose => {
          const treatment = this.newPatientPrescription(
            prescription.prescriptionConfigurationId
          )
          const dose = treatment[initialDose.timepoint]
          if (dose.enabled) {
            if (doseRule.type === prescriptionType.BOLUS_CARB_COUNT) {
              if (
                isNumeric(dose.insulinSensitivityFactor) &&
                isNumeric(dose.insulinToCarbRatio)
              ) {
                prescription.doses.push({
                  treatmentInsulinId: initialDose.treatmentInsulinId,
                  timepoint: initialDose.timepoint,
                  insulinSensitivityFactor: parseFloat(
                    dose.insulinSensitivityFactor
                  ),
                  insulinToCarbRatio: parseFloat(dose.insulinToCarbRatio),
                  auditEntries: treatment.auditLog.getAuditEntries(),
                })
              }
            } else {
              if (isNumeric(dose.value)) {
                const dosingDay =
                  initialDose.timepoint === 'basal_once_weekly'
                    ? treatment.dosingDay
                    : null

                if (dosingDay && !preferredDosingDay) {
                  preferredDosingDay = dosingDay
                }

                prescription.doses.push({
                  treatmentInsulinId: initialDose.treatmentInsulinId,
                  timepoint: initialDose.timepoint,
                  doseValue: parseFloat(dose.value),
                  dosingDay,
                  auditEntries: treatment.auditLog.getAuditEntries(),
                })
              }
            }
          }
        })

        prescriptions.push(prescription)
      })

      const data = {
        patient: {
          patientNumber: this.newPatientInfo.patientNumber,
          siteNumber: this.siteNo,
          sex: this.newPatientInfo.patientSex,
          birthDay: this.newPatientInfo.birthDay,
          birthMonth: this.newPatientInfo.birthMonth,
          birthYear: this.newPatientInfo.birthYear,
          dosingRegimen: this.newPatientInfo.dosingRegimen,
          treatmentArmId: this.newPatientInfo.treatmentArm,
          dosingDay: preferredDosingDay,
          auditEntries: this.newPatientInfoAuditEntries,
        },
        prescriptions,
        appActivation: {
          languageTag: this.newPatientAppSettings.language,
          clockNotation: this.newPatientAppSettings.timeFormat,
          createdAt: dateTimeZone,
          auditEntries: this.newPatientAppSettingsAuditEntries,
        },
        currentClientTime: dateTimeZone,
      }

      this.createPatientLoadingState = loadingState.LOADING

      service
        .createPatient(data)
        .then(response => {
          this.dataForQrCode = JSON.stringify(response)
          this.$emit('patient-added', true)
          this.currentStep = addPatientSteps.QR_LOADED
          this.createPatientLoadingState = loadingState.LOAD_SUCCEEDED
          setTimeout(() => {
            this.pollForAppStatus()
          }, 3000)
        })
        .catch(error => {
          this.createPatientLoadingState = loadingState.LOAD_ERRORED
          this.apiError = null
          const { metadata } = error.response?.data?.domainError || {}
          if (metadata) {
            this.apiPatientNumberError = null
            metadata.forEach(element => {
              if (element.key === 'patientNumber' && element.value) {
                this.apiPatientNumberError = element.value.toString()
                this.currentStep = addPatientSteps.INPUT_PATIENT_INFO
              }
            })
          }

          if (!this.apiPatientNumberError) {
            this.apiError = error
          }
        })
    },
    pollForAppStatus() {
      if (this.newPatientInfo.patientNumber) {
        appService
          .getAppState(this.newPatientInfo.patientNumber)
          .then(response => {
            if (this.isMounted && response.state !== 'AppActivated') {
              setTimeout(() => {
                this.pollForAppStatus()
              }, 3000)
            } else {
              this.currentStep = addPatientSteps.DONE
              this.$emit('app-activated')
            }
          })
      }
    },
    guardOnNavigation() {
      if (this.currentStep >= addPatientSteps.LOADING_QR) return false

      return !!(
        this.newPatientInfo.patientNumber ||
        this.newPatientInfo.patientSex ||
        this.newPatientInfo.birthDay ||
        this.newPatientInfo.birthMonth ||
        this.newPatientInfo.birthYear ||
        this.newPatientInfo.treatmentArm
      )
    },
    confirmNavigation(event) {
      if (this.guardOnNavigation()) {
        const confirmationMessage = 'Changes you made may not be saved'
        event.returnValue = confirmationMessage // Gecko, Trident, Chrome 34+
        return confirmationMessage
      } else {
        return null
      }
    },
  },
  mounted() {
    this.isMounted = true
    window.addEventListener('beforeunload', this.confirmNavigation)
  },
  beforeDestroy() {
    this.isMounted = false
    window.removeEventListener('beforeunload', this.confirmNavigation)
  },
})
</script>

<style lang="scss" scoped>
.active-color {
  color: $nn-sea-blue;
}

.config-divider.v-divider {
  border-color: white !important;
  border-width: 2px !important;
  margin-left: -76px !important;
  margin-right: -76px !important;
  margin-top: 28px !important;

  &.active-color {
    border-color: $nn-sea-blue !important;
  }
}
</style>

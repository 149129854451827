<template>
  <v-form>
    <v-card-title class="font-weight-bold my-2 pb-3"
      >Review and confirm:
    </v-card-title>
    <v-card-subtitle class="font-weight-bold font-size-14">
      Make sure the patient's details match those in IWRS.
    </v-card-subtitle>

    <v-card-text>
      <PatientInfoOverview :show-section-divider="true" />

      <v-row>
        <v-col class="text-right">
          <v-btn rounded @click="prevStep" class="mx-5" data-testid="btn-back"
            >Back</v-btn
          >
          <v-btn
            color="secondary"
            rounded
            @click.prevent="nextStep"
            autofocus
            type="submit"
            data-testid="btn-continue"
          >
            Continue
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import newPatientGettersMixin from '../newPatientGettersMixin'
import PatientInfoOverview from './PatientInfoOverview'

export default {
  name: 'patient-info-confirm',
  mixins: [trialSettingsGettersMixin, newPatientGettersMixin],
  components: { PatientInfoOverview },
  methods: {
    nextStep() {
      this.$emit('next-step', true)
    },
    prevStep() {
      this.$emit('prev-step', true)
    },
  },
}
</script>
